import React from 'react';
import { styled } from '@glitz/react';
import { Breakpoint } from '@avensia/scope';
import { epsilon, beta, alpha, tiny, large, huge, gamma, kappa, pixelsToUnit, white } from 'Shared/Style';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import { Style } from '@glitz/type';

type PropType = {
  title: string;
  text: string;
  buttonText: string;
  linkUrl: string;
  width: string;
  currentBreakpoint: number;
  buttonBackgroundColor: string;
  buttonBackgroundHoverColor: string;
  buttonTextColor: string;
};

const sizes = {
  largest: 5,
  mid: 4,
  smallest: 3,
};

const sizeStyling = {
  header: {
    [sizes.smallest]: { fontSize: epsilon },
    [sizes.mid]: { fontSize: beta },
    [sizes.largest]: { fontSize: alpha },
  },
  content: {
    [sizes.smallest]: { fontSize: kappa, marginTop: tiny, marginBottom: tiny },
    [sizes.mid]: { fontSize: epsilon, marginTop: large, marginBottom: large },
    [sizes.largest]: { fontSize: gamma, marginTop: huge, marginBottom: huge },
  },
  spacing: {
    [sizes.smallest]: { padding: { y: large, x: large } },
    [sizes.mid]: { padding: { y: large, x: large } },
    [sizes.largest]: { padding: { y: huge, x: huge } },
  },
};

const TextWrapper = styled.div({
  textAlign: 'center',
  position: 'absolute',
  left: '0',
  right: '0',
  margin: {
    y: 0,
    x: 'auto',
  },
  bottom: '0',
});

const TextInnerWrapper = styled.div({
  maxWidth: pixelsToUnit(780),
  display: 'inline-block',
  width: '100%',
});

const TextHeader = styled.h2({
  color: white,
  lineHeight: 'normal',
  textTransform: 'uppercase',
});

const TextContent = styled.p({
  color: white,
});

const ActionButton = styled(Button, {
  border: {
    xy: {
      style: 'none',
    },
  },
  font: {
    family: '"Nunito", "PT Sans", "sans-serif"',
    size: epsilon,
    weight: 600,
  },
  letterSpacing: pixelsToUnit(0.8),
  padding: {
    x: huge,
  },
});

function getEstimatedSizes(currentBreakPoint: number, width: string) {
  const isMobile = currentBreakPoint < Breakpoint.Medium;
  let estimatedWidth = isMobile ? sizes.mid : sizes.largest;
  switch (width) {
    case '1:2':
      estimatedWidth = isMobile ? sizes.mid : sizes.mid;
      break;
    case '1:3':
      estimatedWidth = isMobile ? sizes.smallest : sizes.mid;
      break;
    case '2:3':
      estimatedWidth = isMobile ? sizes.mid : sizes.largest;
      break;
    case '1:4':
      estimatedWidth = isMobile ? sizes.mid : sizes.smallest;
      break;
    case '3:4':
      estimatedWidth = isMobile ? sizes.mid : sizes.largest;
      break;
    default:
      break;
  }
  return estimatedWidth;
}

export default (props: PropType) => {
  const { title, text, linkUrl, buttonText, currentBreakpoint, width } = props;
  const size = title || text || buttonText ? getEstimatedSizes(currentBreakpoint, width) : 0;
  const buttonStyling: Style = {
    backgroundColor: !!props.buttonBackgroundColor
      ? props.buttonBackgroundColor
      : (theme) => theme.buttonSecondaryBackgroundColor,
    color: !!props.buttonTextColor ? props.buttonTextColor : (theme) => theme.buttonSecondaryTextColor,
    ':hover': {
      backgroundColor: !!props.buttonBackgroundHoverColor
        ? props.buttonBackgroundHoverColor
        : (theme) => theme.buttonSecondaryHoverBackgroundColor,
    },
  };
  const css = {
    wrapper: {
      width: size ? '100%' : '0',
      ...sizeStyling.spacing[size],
    },
    header: {
      letterSpacing: size < Breakpoint.Large ? pixelsToUnit(0.9) : pixelsToUnit(1.2),
      ...sizeStyling.header[size],
    },
    content: {
      lineHeight: size < Breakpoint.Large ? '1.44' : '1.45',
      letterSpacing: size < Breakpoint.Large ? pixelsToUnit(0.6) : pixelsToUnit(0.7),
      ...sizeStyling.content[size],
    },
  };
  return (
    <TextWrapper css={css.wrapper}>
      <TextInnerWrapper>
        <TextHeader css={css.header}>{title}</TextHeader>
        <TextContent css={css.content}>{text}</TextContent>
        {linkUrl && buttonText && (
          <ActionButton to={linkUrl} css={buttonStyling} appearance={ButtonAppearance.Secondary}>
            {buttonText}
          </ActionButton>
        )}
      </TextInnerWrapper>
    </TextWrapper>
  );
};
