/**
 * @ComponentFor ImageBlockViewModel
 */
import React, { useEffect } from 'react';
import { styled, StyledProps } from '@glitz/react';
import { epiPropertyValue } from '@avensia/scope-episerver';
import connect from 'Shared/connect';
import { BOX_ITEM_CLASSNAME } from 'Shared/BoxLayout';
import Image from '../../Image';
import ImageBlockPropType from './ImageBlockViewModel.type';
import ImageTextBlock from './ImageTextBlock';
import padStyled from 'Shared/Blocks/PaddedBlock';
import Link from 'Shared/Link';
import { trackPromotionImpression, trackPromotionClick } from 'TrackingInformation';

type RequieredProps = ImageBlockPropType;

type ConnectedProps = {
  currentBreakpoint: number;
};

type Props = RequieredProps & ConnectedProps & StyledProps;

const ImageBlock = (props: Props) => {
  const { currentBreakpoint } = props;
  const linkUrl = epiPropertyValue(props.block.linkUrl);
  const titleContent = epiPropertyValue(props.block.title);
  const textContent = epiPropertyValue(props.block.text);
  const buttonText = epiPropertyValue(props.block.button);
  const buttonTextColor = epiPropertyValue(props.block.buttonTextColor);
  const buttonBackgroundColor = epiPropertyValue(props.block.buttonBackgroundColor);
  const buttonBackgroundHoverColor = epiPropertyValue(props.block.buttonBackgroundHoverColor);
  const backgroundColorTransparent = epiPropertyValue(props.block.imageBackgroundColorTransparent);
  const textComponent = (
    <ImageTextBlock
      title={titleContent}
      text={textContent}
      buttonText={buttonText}
      linkUrl={linkUrl && linkUrl.url}
      width={props.width}
      currentBreakpoint={currentBreakpoint}
      buttonTextColor={buttonTextColor}
      buttonBackgroundColor={buttonBackgroundColor}
      buttonBackgroundHoverColor={buttonBackgroundHoverColor}
    />
  );

  useEffect(() => {
    trackPromotionImpression(props.block.promotionImpression);
  }, []);

  const trackPromoClick = () => trackPromotionClick(props.block.promotionImpression);

  const imageComponent = (
    <Image
      onClick={trackPromoClick}
      css={{
        display: 'block',
        objectFit: 'cover',
        width: '100%',
        backgroundColor: backgroundColorTransparent ? 'transparent' : 'white',
      }}
      src={epiPropertyValue(props.block.imageUrl).url}
      alt={epiPropertyValue(props.block.altText)}
      lazy
    />
  );

  return (
    <styled.Div css={props.compose()} className={BOX_ITEM_CLASSNAME}>
      {linkUrl ? (
        <Wrapper>
          <RedirectWrapper to={linkUrl.url}>
            {imageComponent}
            {textComponent}
          </RedirectWrapper>
        </Wrapper>
      ) : (
        <Wrapper>
          {imageComponent}
          {textComponent}
        </Wrapper>
      )}
    </styled.Div>
  );
};

export default connect((state) => ({
  currentBreakpoint: state.currentBreakpoint,
}))(padStyled(ImageBlock));

const Wrapper = styled.div({
  position: 'relative',
  display: 'block',
  textAlign: 'center',
});

const RedirectWrapper = styled(Link, {
  cursor: 'pointer',
});
